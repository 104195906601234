// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from '@chakra-ui/react'
const theme = {
  colors: {
    primary: 'rebeccapurple',
    brand: {
        100: "#f7fafc",
        // ...
        900: "#1a202c",
      },
    },
}

/*

  colors: {
    brand: {
      100: "#f7fafc",
      // ...
      900: "#1a202c",
    },
  },

*/


export default extendTheme(theme)
